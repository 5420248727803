import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { useMsal } from '@azure/msal-react';

import TabMenu from './TabMenu'
import { getTokenType } from '../../utils/session';
import { logout } from '../../utils/services';
import { gEnv, gSubdomain, getRelyingParty } from '../../utils/config';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Components
import Alert from './Alert';

// Styles
import styles from './Navbar.module.css';

// Images
import cogPng from './cog-sharp.png';

const Navbar = () => {
  const location = useLocation();
  const history = useHistory();
  const {relyingParty, setRelyingParty} = useRelyingParty();
  const [logoutTimerId, setLogoutTimerId] = useState(null);
  const idleTimer = useRef(null);
  const { instance } = useMsal();
  const [alerts, setAlerts] = useState([]);
  const idleTimeoutSeconds = relyingParty?.agentIdleTimeoutSeconds || (8 * 60 * 60) - (5 * 60); // (8 hours) - (5 minutes)

  useEffect(() => {
    async function __getRelyingPartyInfo() {
      const rpResult = await getRelyingParty();
      setRelyingParty(rpResult?.data);
      const rpName = rpResult?.data?.name;
      const title = (gEnv==='PROD') 
                  ? `IDgo Admin - ${rpName}` 
                  : `IDgo Admin - ${rpName} ${gEnv}`
                  ;
      document.title = title;
    };
    if (relyingParty?.name) return;
    __getRelyingPartyInfo();  
  }, [relyingParty, setRelyingParty]);

  function showNavbar() {
    if (location?.pathname === '/'               ) return false;
    if (location?.pathname === '/forgot-password') return false;
    if (location?.pathname === '/profile/'       ) return false;
    if (location?.pathname.startsWith('/reset-password')) return false;
    return true;
  }

  const onActive = async () => {
    if (alerts && alerts.constructor===Array && alerts.length>0) {
      idleTimer.current.reset();
      setAlerts([]);
      clearTimeout(logoutTimerId);
      setLogoutTimerId(null);
    }
  };

  const onIdle = (e) => {
    const idleDurationTimestamp = new Date(idleTimeoutSeconds * 1000).toISOString();
    const idleHH = idleDurationTimestamp.slice(11,13);
    const idleMM = idleDurationTimestamp.slice(14,16);
    let idleTime = '';
    if (idleHH!=='00') idleTime += `${idleHH} hours and `
    if (idleMM!=='00') idleTime += `${idleMM} minutes`
    if (!idleTime) {
      const idleSS = idleDurationTimestamp.slice(17,19);
      idleTime = `${idleSS} seconds`
    }
    const msg = `Are you still there? You have been inactive for at least ${idleTime}.
    Click on me or you will be logged out in 5 minutes.`;
    setAlerts([...alerts, {heading: `Hey!`, msg, type:'warning'}]);
    const logoutTimeoutSeconds = relyingParty?.agentLogoutTimeoutSeconds || (5 * 60); // 5 minutes
    const currentLogoutTimerId = setTimeout(() => {
        setAlerts([]);
        logout();
        window.location.reload();
      }, logoutTimeoutSeconds*1000);
    setLogoutTimerId(currentLogoutTimerId);
  };

  const onSignOut = async () => {
    const tokenType = await getTokenType();
    if (tokenType==='aad') {
      await instance.logout();
      // fall through
    }
    logout();
    history.push({ pathname: '/', hash: gSubdomain });
  };

  return (
    <> { showNavbar() &&
        <>
          <IdleTimer
            ref={idleTimer}
            element={document}
            onActive={(e) => onActive(e)}
            onIdle={(e) => onIdle(e)}
            debounce={500}
            timeout={idleTimeoutSeconds*1000}
          />
          <Alert alerts={alerts} />
          <div className={styles.navbarHeader}>
            <h6>IDgo Admin - {relyingParty?.name}</h6>
            <div className={styles.navbarSignout}>
              <Link to={'/profile/#'+gSubdomain} className='btn btn-link'>
                <img src={cogPng} className={styles.cogButton} alt='settings'/>
              </Link>
              <button className={'btn btn-primary ' + styles.signoutButton} onClick={onSignOut}>
                Sign Out
              </button>
            </div>
            <div className='row'>
              <div className='col-lg-3'></div>
            </div>
            <TabMenu />
          </div>
        </>
      }
    </>
  );
};

export default Navbar;