import React, { createContext, useState, useMemo, useContext } from 'react';

const RelyingPartyContext = createContext();

export function RelyingPartyProvider(props) {
  const [relyingParty, setRelyingParty] = useState({});
  const providerRelyingParty = useMemo(() => ({ relyingParty, setRelyingParty }), [relyingParty]);

  return (
    <RelyingPartyContext.Provider
      value={providerRelyingParty}
      {...props}
    ></RelyingPartyContext.Provider>
  );
}

export function useRelyingParty() {
  return useContext(RelyingPartyContext);
}