import React, { useState } from "react";
import PropTypes from "prop-types";

const PasswordInput = ({
  id,
  value,
  name,
  placeholder,
  onChange,
  invalidMessage,
}) => {
  const [passwordShowHide, setPasswordShowHide] = useState(false);

  const changeType = (e) => {
    e.preventDefault();

    let passwordInput = document.getElementById(id);

    if (passwordInput.type === "text") {
      passwordInput.type = "password";
      setPasswordShowHide(false);
    } else {
      passwordInput.type = "text";
      setPasswordShowHide(true);
    }

    passwordInput.focus();
  };

  const handleOnFocus = (e) => {
    e.preventDefault();
    const showHideBtn = document.getElementById(`${id}-btn`);

    showHideBtn.setAttribute("style", "border-color: #0a5397;");
  };

  const handleOnBlur = (e) => {
    e.preventDefault();
    const showHideBtn = document.getElementById(`${id}-btn`);

    showHideBtn.setAttribute("style", "border-color: #ced4da;");
  };

  return (
    <div
      id={`${id}-input-group`}
      className="input-group form-label-group mx-auto"
    >
      <input
        id={id}
        type="password"
        aria-label="Password"
        name={name}
        placeholder={placeholder}
        value={value}
        autoComplete="off"
        onChange={(e) => onChange(e)}
        onFocus={(e) => handleOnFocus(e)}
        onBlur={(e) => handleOnBlur(e)}
        className="form-control"
        maxLength="128"
        required
      />
      <div className="input-group-append">
        <button
          id={`${id}-btn`}
          type="button"
          className="btn"
          onClick={(e) => changeType(e)}
        >
          {passwordShowHide ? (
            <img
              src={`${process.env.PUBLIC_URL}/icons/icn_view_blu.png`}
              alt="Show or hide icon for the password input field"
              style={{ width: "24px", height: "18px" }}
            ></img>
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/icons/icn_hide_blu.png`}
              alt="Show or hide icon for the password input field"
              style={{ width: "24px", height: "24px" }}
            ></img>
          )}
        </button>
      </div>
      <label htmlFor={id}>{placeholder}</label>
      <div className="invalid-feedback">{invalidMessage}</div>
    </div>
  );
};

PasswordInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default PasswordInput;
