export const setValidPasswordInput = (id) => {
  let passwordInput = document.getElementById(id);

  let formControl = passwordInput
    .getElementsByClassName("form-control")
    .item(0);

  let showHideBtn = passwordInput.getElementsByClassName("btn").item(0);

  let invalidFeedback = passwordInput
    .getElementsByClassName("invalid-feedback")
    .item(0);

  formControl.classList.remove("invalid");
  formControl.classList.remove("was-validated");

  invalidFeedback.style.display = "none";

  showHideBtn.classList.remove("invalid");

  // TODO: I ain't thrilled with this but it supports the validation pattern I am using very well
  // TODO: Come up with a better solution to returning truthy or falsy values to the caller
  return true;
};

export const setInvalidPasswordInput = (id) => {
  let passwordInput = document.getElementById(id);

  let inputFormControl = passwordInput
    .getElementsByClassName("form-control")
    .item(0);

  let invalidFeedback = passwordInput
    .getElementsByClassName("invalid-feedback")
    .item(0);

  let showHideButton = passwordInput.getElementsByClassName("btn").item(0);

  inputFormControl.classList.add("invalid");

  invalidFeedback.style.display = "block";

  showHideButton.classList.add("invalid");
  showHideButton.classList.remove("was-validated");

  return false;
};

export const setValidInput = (id) => {
  document.getElementById(id).classList.remove("was-validated");
  document
    .getElementById(id)
    .getElementsByClassName("form-control")
    .item(0)
    .classList.remove("invalid");

  document
    .getElementById(id)
    .getElementsByClassName("invalid-feedback")
    .item(0).style.display = "none";

  return true;
};

export const setInvalidInput = (id) => {
  document
    .getElementById(id)
    .getElementsByClassName("form-control")
    .item(0)
    .classList.add("invalid");

  document
    .getElementById(id)
    .getElementsByClassName("invalid-feedback")
    .item(0).style.display = "block";

  return false;
};
