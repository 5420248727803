import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Utils
import { changeEmail, logout } from '../../utils/services';
import { getUser } from '../../utils/session';

// Components
import InputValidation from '../validation/InputValidation';
import Alert from '../layout/Alert';

// Utils
import { isValidEmail } from '../../validation-utils/validationCheck';
import { setValidInput, setInvalidInput } from '../../validation-utils/toggleValidationFeedback';

const ChangeEmail = () => {
  const history = useHistory();
  const [currentEmail, setCurrentEmail] = useState();
  const [newEmail, setNewEmail] = useState();
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const user = getUser();
    setCurrentEmail(user?.email);
  }, []);

  const onChange = (e) => {
    setNewEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validEmail = isValidEmail(newEmail);

    if (validEmail) setValidInput(`email-input-group`);
    else setInvalidInput(`email-input-group`);

    if (!validEmail) return;

    const result = await changeEmail(newEmail);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      return;
    }

    logout();
    history.push('/change-email-success', { newEmail: newEmail });
  };

  const handleClear = () => {
    setNewEmail('');
  };

  return (
    <>
      <InputValidation formName='changeEmailForm' />
      <div className='card'>
        <div className='card-header border-bottom-0'>
          <h5>Email Address</h5>
        </div>
        {/* the 1st 4 form attributes were added per 2024 WEBSEC pentest recommendations */}
        <form
          spellCheck='false'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          name='changeEmailForm'
          method='post'
          onSubmit={(e) => onSubmit(e)}
          noValidate
        >
          <div className='card-body'>
            <Alert alerts={alerts}/>
            <h6>Change Your Email Address</h6>
            <div className='form-group'>
              <label className='text-muted' style={{ margin: '0px' }}>
                <small>Current email address</small>
              </label>
              <p>{currentEmail}</p>
            </div>
            <div id='email-input-group' className='form-label-group mb-5'>
              <input
                id='email-input'
                name='newEmail'
                type='email'
                className='form-control'
                aria-label='New email address'
                autoComplete='off'
                placeholder='Enter new email address'
                value={newEmail}
                onChange={(e) => onChange(e)}
              />
              <label htmlFor='email-input'>Email address</label>
              <div className='invalid-feedback'>
                Please enter a valid email address
              </div>
            </div>
          </div>
          <div className='card-footer border-top-0'>
            <div className='text-center'>
              <input
                type='button'
                value='Clear'
                className='btn btn-outline-primary btn-sm mr-3 shadow-none'
                style={{ width: '40%' }}
                onClick={handleClear}
              />
              <input
                type='submit'
                value='Save'
                className='btn btn-primary btn-sm shadow-none'
                style={{ width: '40%' }}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangeEmail;