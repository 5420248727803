import React from "react";
import { Link } from "react-router-dom";
import { gSubdomain } from '../../utils/config';

const BackToUserList = () => {
  return (
    <Link to={`/user-list/#${gSubdomain}`}>
      <h3 className="btn btn-link btn-lg">
        <b style={{fontSize:'20px'}}>&lt;</b> Back to Registered IDgo Agent accounts
      </h3>
    </Link>
  );
};

export default BackToUserList;
