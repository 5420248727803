import React from 'react';
import moment from 'moment';
import { ReportTable } from './ReportTable';
import { getPendingEnrollments } from '../../utils/services';

const PendingEnrollments = () => {
  const columns = React.useMemo(() => 
    [
      { Header: 'Enrollment Activity'
      , columns: 
        [ 
          { Header: 'Mobile'
          , disableFilters: true
          , accessor: d => {
              const cleaned = ('' + d.mobileNumber.slice(-10)).replace(/\D/g, '');
              const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
              if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
              }
              return d.mobileNumber;
            }
          }
        , { Header: 'Verification'
          , accessor: 'verification'
          , disableFilters: true
          }
        , { Header: 'Date'
          , disableFilters: true
          , accessor: d => {
              if (d.createdDtm){
                return moment.utc(d.createdDtm).local().format('lll (ddd)');
              }
              return '';
            }
          , Cell: row => ( row.value ? ( <span>{(row.value)}</span> )
                                     : ( <span style={{color:'orange', fontWeight: 'bold'}}>Pending</span> )
                         )
          }
        ,
        ]
      }
    ], []);
  
  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='round-border card'>
            <ReportTable
              header='Pending Enrollments' 
              columns={columns} 
              reportName='pendingEnrollments' 
              refreshDataFunc={getPendingEnrollments}
            />
            <div className='card-footer border-top-0'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingEnrollments;