import React from 'react';

//Styles
import styles from './Alert.module.css';

const Alert = ({ alerts }) => {

  const renderAlerts = () => {
    if (!alerts || alerts.length <= 0) return <></>;
    return <>
      { alerts.map((alert, index) => {
        return <div key={index} className={styles.alertWrapper}>
          { alert.type==='warning' ? (
              <div className={`${styles.alert} ${styles.alertWarning}`}>
                <p className={`${styles.alertWarningHeading}`}>{alert.heading}</p>
                <p className={`${styles.alertWarningMessage}`}>{alert.msg}</p>
              </div>
            ) : (
              <div className={`${styles.alert} ${styles.alertDanger}`}>
                <p className={`${styles.alertDangerHeading}`}>{alert.heading}</p>
                <p className={`${styles.alertDangerMessage}`}>{alert.msg}</p>
              </div>
            )
          }
          </div>
        })
      }
    </>
  };

  return (
    <>{ renderAlerts() }</>
  );
};

export default Alert;