import React from 'react';
import moment from 'moment';
import { ReportTable } from './ReportTable';
import { getEnrollmentActivity } from '../../utils/services';

const EnrollmentActivity = () => {
  const columns = React.useMemo(() => 
    [
      { Header: 'Enrollment Activity'
      , columns: 
        [ { Header: 'Date'
          , disableFilters: true
          , accessor: d => {
              return moment.utc(d.createdDtm).local().format('lll (ddd)')
            }
          }
        , { Header: 'Mobile'
          , disableFilters: true
          , accessor: d => {
              const cleaned = ('' + d.loginValue.slice(-10)).replace(/\D/g, '');
              const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
              if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
              }
              return d.loginValue;
            }
          }
        , { Header: 'Agent'
          , accessor: 'createdBy'
          , disableFilters: true
          }
        , { Header: 'Activity'
          , accessor: 'proofDisplayName'
          , disableFilters: true
          }
        ]
      }
    ], []);
  
  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='round-border card'>
            <ReportTable
              header='Enrollment Activity' 
              columns={columns} 
              reportName='enrollmentActivity' 
              refreshDataFunc={getEnrollmentActivity}
            />
            <div className='card-footer border-top-0'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollmentActivity;