import React from 'react';

// Components
import BackToLogin from '../common/BackToLogin';

const ChangeEmailSuccess = (props) => {
  const { newEmail } = props.location.state;

  return (
    <>
      <div
        className='row justify-content-center align-items-center'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -75%)',
        }}
      >
        <div
          className='card mx-auto'
          style={{
            width: '50%',
            height: '50%',
            minWidth: '500px',
            minHeight: '375px',
            borderRadius: '6px',
          }}
        >
          <div className='text-center mx-auto' style={{ marginTop: '10px' }}>
            <div className='card-body'></div>
            <div className='mb-4'>
              <p>Your changes have been saved! </p>
              <strong>
                <u>Please sign in with your updated email address.</u>
              </strong>
            </div>
            <div className='form-group mb-2'>
              <label
                className='text-muted'
                style={{ margin: '0px' }}
                htmlFor='currentEmail'
              >
                <small>Updated email address</small>
              </label>
              <p>{newEmail}</p>
            </div>
            <BackToLogin />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeEmailSuccess;