import React from 'react';
import moment from 'moment';
import { ReportTable } from './ReportTable';
import { getAgentEnrollAndFirstAuth } from '../../utils/services';

const AgentEnrollAndFirstAuthReport  = () => {
  const columns = React.useMemo(
    () => [
      { Header: 'Agent Enroll & 1st Auth'
      , columns: 
        [ { Header: 'Date'
          , disableFilters: true
          , accessor: d => {
              return moment.utc(d.createdDtm).local().format('lll (ddd)')
            }
          }
        , { Header: 'Mobile'
          , disableFilters: true
          , accessor: d => {
              const cleaned = ('' + d.loginValue.slice(-10)).replace(/\D/g, '');
              const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
              if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
              }
              return d.phoneNumber;
            }
          }
        , { Header: 'Agent'
          , accessor: 'initiatedBy'
          , disableFilters: true
          }
        , { Header: 'Activity'
          , accessor: 'activityType'
          , disableFilters: true
          }
        ]
      }
    ],
    []
  )
  
  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='round-border card'>
            <ReportTable 
              header='Agent Enroll &amp; 1st Auth'
              columns={columns} 
              reportName='agentEnrollAndFirstAuth' 
              refreshDataFunc={getAgentEnrollAndFirstAuth}
            />
            <div className='card-footer border-top-0'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentEnrollAndFirstAuthReport;