import React from 'react';

import BackToUserList from '../common/BackToUserList';

const EditUserSuccess = () => {
  return (
    <>
      <div className='card-body'>
        <div className='mb-4'>
          <p className='lead'>Your changes have been saved!</p>
        </div>
        <BackToUserList />
      </div>
      <div className='card-footer'></div>
    </>
  );
};

export default EditUserSuccess;