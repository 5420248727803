import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { jwtDecode } from 'jwt-decode';

import { gSubdomain } from '../../utils/config';
import { getAccessToken } from '../../utils/session';
import { getUsers, deleteUser } from '../../utils/services';

//Images
import checkPng from './checkmark-sharp.png';
import pencilPng from './edit.png';
import trashPng from './trash-a.png';

// used to create role headers and checkmark values
const roles = 
[ { hdr:'Admin'              , name:'isAdmin'}
, { hdr:'Enroll Members'     , name:'isAutoEnroller'}
, { hdr:'Revoke'             , name:'isRevoker'}
, { hdr:'View Reports'       , name:'isReportViewer'}
, { hdr:'No Whitelist Access', name:'isNonWhitelistAccessAllowed'}
];

const UserList = () => {
  const [selectedUserId, setSelectedUserId] = useState('');
  const [csvTable, setCsvTable] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [alerts, setAlerts] = useState([]);

  const fetchAllUsers = async () => {
    const result = await getUsers();

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      return;
    }

    setUsers(result.data);
  };

  useEffect(() => {
    async function __currentUserIsAdmin() {
      const {token} = await getAccessToken();
      if (!token) return;
      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken?.roles?.includes('admin'));
    };

    __currentUserIsAdmin();
    fetchAllUsers();
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (agentId) => {
    const result = await deleteUser(agentId);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      return;
    }

    await fetchAllUsers();
  };

  const renderWordBrokeHeader = (hdr) => {
    const words = hdr.split(' ');
    return <>
      { words.map((word) => {return <span key={word} style={{whiteSpace:'nowrap'}}>{word}</span> }) }
    </>
  };

  const formatCSV = () => {
    // Maps the report data from the server to the CSV output
    const dataMap = 
      [ {createdDtm: 'created_at'}
      , {firstName: 'firstName'} 
      , {lastName: 'lastName'}
      , {emailAddress: 'email'}
      , {isAdmin: 'isAdmin'}
      , {isAutoEnroller: 'isAutoEnroller'}
      , {isRevoker: 'isRevoker'}
      , {isReportViewer: 'isReportViewer'}
      , {isNoWhitelistAccessAllowed: 'isNonWhitelistAccessAllowed'}
      ];

    // build the header row
    let rows = [];
    rows.push(dataMap.map( (col) => { return Object.keys(col) } ));

    // add the rows
    if (users?.length) {
      for (const user of users) {
        let row = [];
        for (const col of dataMap) {
          const key = Object.values(col);
          row.push(`${user[key]}`);
        }
        rows.push(row);
      }
    }
    
    setCsvTable(rows);
  };

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='round-border card'>
            <div className='card-header border-bottom-0 mb-0'>
              <div className='row mb-3'>
                <div className='col-lg-12'>
                  <h4 className='float-left'>Registered IDgo Agent accounts</h4>
                  { isAdmin ?
                    <>
                      <CSVLink 
                        className='float-right btn btn-primary' 
                        style={{ width: '140px', marginLeft: '50px' }} 
                        data={csvTable} 
                        asyncOnClick={true}
                        onClick={() => formatCSV()}
                        filename={'agents.csv'}
                      >
                        Export to CSV
                      </CSVLink>
                      <Link to={'/user-registration/#'+gSubdomain} className='float-right btn btn-primary' style={{ width: '140px' }}>
                        Add a User
                      </Link>
                    </> : ''
                  }
                </div>
              </div>
              <div className='row align-items-end'>
                <div className='col-sm-2' style={{whiteSpace:'nowrap', color:'blue'}}>
                  Name
                </div>
                <div className='col-sm-2' style={{whiteSpace:'nowrap', color:'blue'}}>
                  Email Address
                </div>

                {roles.map((role) => (
                  <div key={role.name} className='text-center small col-sm-1' style={{color:'blue'}}>
                    {renderWordBrokeHeader(role.hdr)}
                  </div>
                ))}

              </div>
            </div>
            <div className='card-body'>
              {users.map((user) => (
                <div key={user.id}>
                  <div className='row'>

                    <div className='col-sm-2' style={{whiteSpace:'nowrap'}}>
                      {user.firstName + ' ' + user.lastName}
                    </div>
                    <div className='col-sm-2'>
                      {user.email}
                    </div>

                    {roles.map((role) => (
                      <div key={role.name} className='text-center col-sm-1'>
                        {user[role.name] ? <img src={checkPng} style={{width:'24px'}} alt='checkmark'/> : ''}
                      </div>
                    ))}

                    { isAdmin ?
                      <div className='col-sm-1' style={{whiteSpace:'nowrap',paddingLeft:0}}>
                        <Link to={{ pathname:'/edit-user', state:{...user} }}>
                          <img src={pencilPng} style={{width:'20px', marginBottom:'8px'}} alt='edit'/>
                        </Link>
                        <button
                          type='button'
                          className='btn btn-link'
                          data-bs-toggle='modal'
                          data-bs-target='#removeUser'
                          onClick={(e) => setSelectedUserId(user.id)}
                        >
                          <img src={trashPng} style={{width:'22px', marginLeft: '14px', marginBottom:'10px'}} alt='delete'/>
                        </button>
                      </div> : ''
                    }
                  </div>
                  <hr className='m-2' />
                </div>
              ))}
            </div>
            <div className='card-footer border-top-0'></div>
          </div>
        </div>
      </div>
      {/* 'Are you sure want to remove this user from IDgo?' Modal*/}
      <div
        className='modal fade'
        id='removeUser'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalCenterTitle'>
                Remove User
              </h5>
              <button
                type='button'
                className='close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              Are you sure you want to remove this user from IDgo?
            </div>
            <div className='modal-footer mx-auto'>
              <button
                type='button'
                className='btn btn-outline-secondary'
                style={{ width: '100px' }}
                data-bs-dismiss='modal'
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-outline-danger'
                style={{ width: '100px' }}
                data-bs-dismiss='modal'
                onClick={(e) => handleDelete(selectedUserId)}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;