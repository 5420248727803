import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Emoji from '../common/Emoji';

// Utils
import { gEnv, gSubdomain } from '../../utils/config';

const ResetPasswordSuccess = (props) => {
  return (
    <>
      <div
        className='row justify-content-center align-items-center'
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -75%)' }}
      >
        <div
          className='card mx-auto'
          style={{ width: '50%', height: '50%', minWidth: '400px', minHeight: '300px', borderRadius: '6px' }}
        >
          <div className='text-center mx-auto' style={{ marginTop: '10px' }}>
            <div className='card-body'>
              <div style={{ marginBottom: '20px' }}>
                <p style={{ fontSize: '16pt', marginTop: '20%', marginBottom: '20px' }}>
                  Your password has successfully been reset! {` `}
                  <Emoji symbol='🥳' label='partying face' />
                </p>
              </div>
              <div>
                <a href={'https://agent'+((props.isNonWhitelistAccessAllowed) ? '':'2') +`.${gEnv}.cozera.io/#${gSubdomain}`} className='btn btn-primary btn-lg shadow-none'>
                  Login to IDgo Agent
                </a>
              </div>
              <div style={{ marginTop: '10pt' }}>
                <Link to={`/#${gSubdomain}`} className='btn btn-primary btn-lg shadow-none'>
                  Login to IDgo Admin
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordSuccess;