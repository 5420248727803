export const isValidEmail = (email) => {
  if (email !== undefined && email !== null) {
    let emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  }
};

export const isValidPassword = (password) => {
  if (password !== undefined && password !== null) {
    return password.length > 0;
  }
};

export const valuesMatch = (a, b) => {
  if (a !== undefined && a !== null && b !== undefined && b !== null) {
    return a === b;
  }
};

export const isEmpty = (inputValue) => {
  return ( inputValue !== undefined && inputValue !== null && inputValue.length > 0 );
};
