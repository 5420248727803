import React from "react";
import { Link } from "react-router-dom";
import { gSubdomain } from '../../utils/config';

const BackToLogin = () => {
  return (
    <Link to={`/#${gSubdomain}`}>
      <h3 className="btn btn-link btn-lg">
        <i className="fas fa-chevron-left"></i> Take me to Sign In Page
      </h3>
    </Link>
  );
};

export default BackToLogin;
