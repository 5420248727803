import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const InputValidation = ({ formName }) => {
  useEffect(() => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    let form =
      formName !== null && formName !== undefined
        ? document.getElementsByName(formName).item(0)
        : null;

    if (form) {
      // Get all form-groups in need of validation
      let validateGroup = document.getElementsByClassName(
        `${formName}-validate-me`
      );

      form.addEventListener(
        'submit',
        (event) => {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }

          //Added validation class to all form-groups in need of validation
          // eslint-disable-next-line
          Array.from(validateGroup).map((element) => {
            element.classList.add(`was-validated`);
          });
        },
        false
      );
    }
    // eslint-disable-next-line
  }, []);

  return <></>;
};

InputValidation.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default InputValidation;