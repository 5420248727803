import React from 'react';

// Components
import BackToUserList from '../common/BackToUserList';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';

const Profile = () => {
  return (
    <>
      <div className='row'>
        <BackToUserList />
      </div>
      <div className='row'>
        <div className='col-lg-6' style={{ marginBottom: '25px' }}>
          <ChangeEmail />
        </div>
        <div className='col-lg-6'>
          <ChangePassword />
        </div>
      </div>
    </>
  );
};

export default Profile;