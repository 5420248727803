import React from 'react';
import moment from 'moment';
import { ReportTable } from './ReportTable';
import { getAuthentications } from '../../utils/services';

const _buildAccessor = (field) => {
  if (field.endsWith('date')) {
    return (d) => {
      return moment.utc(d[field]).local().format('lll (ddd)');
    };
  }
  if (field === 'mobileNumber') {
    return (d) => {
      const cleaned = ('' + d[field].slice(-10)).replace(/\D/g, '');
      const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
      return (match) ? '(' + match[1] + ') ' + match[2] + '-' + match[3] : d[field];
    };
  }
  return field;
};

const _buildDynamicColumns = (colHeaders) => {
  const columns = [];
  for (const i in colHeaders) {
    const field  = colHeaders[i][0];
    const header = colHeaders[i][1];
    columns.push(
      { accessor        : _buildAccessor(field)
      , Header          : header
      , disableFilters  : true
      , fieldName       : field
      });
  }
  return [{ Header: 'Authentication activity', columns} ];
};

const AuthenticationReport = () => {
  const columns = [{ Header: 'Authentication activity', columns: []}];
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='round-border card'>
            <ReportTable 
              header='Authentications' 
              columns={columns} 
              reportName='authentications' 
              refreshDataFunc={getAuthentications}
              columnBuilder={_buildDynamicColumns}
            />
            <div className='card-footer border-top-0'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthenticationReport;