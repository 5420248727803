import React from 'react';

// Components
import BackToLogin from '../common/BackToLogin';

const ChangePasswordSuccess = () => {
  return (
    <>
      <div
        className='row justify-content-center align-items-center'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -75%)',
        }}
      >
        <div
          className='card mx-auto'
          style={{
            width: '50%',
            height: '50%',
            minWidth: '500px',
            minHeight: '375px',
            borderRadius: '6px',
          }}
        >
          <div className='text-center mx-auto' style={{ marginTop: '10px' }}>
            <div className='card-body'>
              <div style={{ marginBottom: '100px' }}>
                <p>Your password has been changed! </p>
                <strong>
                  <u>Please sign in with your new password.</u>
                </strong>
              </div>
              <BackToLogin />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordSuccess;