import React from 'react';
import PropTypes from 'prop-types';

import BackToUserList from '../common/BackToUserList';

const UserRegistrationSuccess = ({ firstName, lastName }) => {
  return (
    <>
      <div className='card-body'>
        <div className='mb-4'>
          <p className='lead'>
            {firstName} {lastName} has been added!
          </p>
        </div>
        <BackToUserList />
      </div>
      <div className='card-footer'></div>
    </>
  );
};

UserRegistrationSuccess.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
};

export default UserRegistrationSuccess;